import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { currencyHelper } from '../../../helpers/currencyHelper';

const RecurringPayments = ({ recurringPaymentData = null, onClick = null }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCancelRecurringPayment = () => {
    setShowDropdown(!showDropdown);
    onClick();
  };

  return (
    <>
      <div className="my-4 font-weight-bold d-flex justify-content-between align-items-center text-13">
        <div className="mt-2">Monthly recurring amount</div>

        <div
          className="d-flex justify-center  edit-recurring-payment-wrapper text-blue"
          onClick={toggleDropdown}
        >
          {currencyHelper(
            recurringPaymentData?.amount,
            recurringPaymentData?.currency?.assetName,
          )}
          <SVG
            className="mb-1  ml-5 edit-recurring-payment-icon"
            onClick={() => toggleDropdown()}
            src={toAbsoluteUrl(`/media/svg/ndovu/${'edit'}.svg`)}
          />
        </div>

        {showDropdown && (
          <div
            className="border-light-blue-545 text-13   edit-recurring-payment-card"
            onClick={handleCancelRecurringPayment}
          >
            <span>Cancel recurring payment</span>
          </div>
        )}
      </div>
    </>
  );
};

export default RecurringPayments;
